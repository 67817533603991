.contGralCarrusel{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
}
.cont-img-btns{
    position: relative;
    width: 100%;
}
.btn-carrusel-prev, .btn-carrusel-next{
    width: auto;
    height: auto;
    position: absolute;
    top: 50%;
    display: flex;
    justify-content: center;
    transform: translateY(-50%);
    background-color: rgba(247, 247, 242, 0.445);
    border: none;
    color: rgb(9, 9, 9);
    font-size: 18px;
    cursor: pointer;
    z-index: 2;
}
.btn-carrusel-prev{
    left: 10px;
}
.img-carrusel{
    width: 100%;
    height: 550px;
    border-radius: 10px;
}
.btn-carrusel-next{
    right: 10px;
}
.cont-imgs-peq{
    width: 100%;
}
.tooltipImg {
    position: absolute;
    top: 50px; /* Position the tooltip above the button */
    left: 20%;
    width: 120px;
    visibility: visible;
    background-color: rgb(221, 8, 8);
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 5px;    
    z-index: 1;    
    opacity: 0.9;
    transition: opacity 0.3s;
}
/* modal */
.modal-overlay {
    position: fixed;
    top: 70px;
    /* centrar eje x */
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;    
    z-index: 1000;
}

/* responsive */
@media screen and (max-width: 845px) {
    /* estilo para la imagen del carrusel en DETALLE */
    .img-carrusel {
        height: 300px;
    }
    .modal-overlay {
        top: 0;
    }
}
