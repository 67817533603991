.contGralListaP {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}
.contListaP {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.no-props{
    min-width: 80vw;
    display: flex;
    justify-content: center;
}
/* responsive */

@media screen and (max-width: 950px) {
    .contListaP{
        width: 90%;
    }
}
@media screen and (max-width: 700px) {
    .contListaP{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    
}
@media screen and (max-width: 600px) {
    .cont-card{
        width: 100%;
        display: flex;
        justify-content: center;
    }
}