.cont-listaPropsAdmin{
    width: 100%;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}
.msj-girar-pantalla{
    display: none;
}

/* responsive */
@media screen and (max-width: 500px) {
    .msj-girar-pantalla{
        display: block;
        color: red;
        font-weight: 400;
    }
}
