.contGralDetalle{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: start; /* eje y */
    background-color: rgb(239, 237, 237);
}
.cont-detail{
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
}
/* estilos seccion titulo y btn atras, video */
.info-1{
    width: 95%;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
.cont-titulo-detalle {
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: #fff;
}
.cont-btns-direccion{
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-top: 10px;
}
.cont-titulo-icono-direcc{
    display: flex;
    justify-content: center;
    align-items: center;
}
.btn-volver,
.btn-video {
    display: flex;
    align-items: center;
    width: auto;
    color: var(--primary-color);
    background-color: var(--background-color);
    border-radius: 10px;
    font-family: Poppins;
    font-weight: 800;
    font-size: medium;
    cursor: pointer;
    margin-right: 20px;
}
.btn-video:hover,
.btn-volver:hover {
    color: var(--background-color);
    background-color: var(--primary-color);
}
/* pos msj btn video */
.tooltip {
    position: absolute;
    bottom: 150; /* Position the tooltip above the button */
    left: 15%;
    width: 150px;
    visibility: visible;
    background-color: rgb(221, 8, 8);
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 5px;    
    z-index: 1;    
    opacity: 0.9;
    transition: opacity 0.3s;
}
/* pos msj btn volver */
.tooltipVolver {
    left: 150px;
    width: auto;
    bottom: 150; /* Position the tooltip above the button */
    left: 15%;  
    visibility: visible;
    background-color: rgb(221, 8, 8);
    color: #fff;
    text-align: center;
    border-radius: 4px;
    padding: 5px;
    position: absolute;
    z-index: 1;  
    opacity: 0.9;
    transition: opacity 0.3s;
}
.detalle-titulo-prop{
    margin-left: 10px;
    font-size: 28px;
    font-weight: 500;
}
.detalle-titulo-direccion{
    margin: 3px;
    font-size: 20px;
    color: black;
}
.cont-moned-precio-detalle {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
}
.detalle-precio{
    margin-right: 10px;
    font-size: 20px;
    font-weight: 500;
    color: var(--primary-color);
}
/* estilos seccion imagenes */
.cont-imgs-info{
    width: 85%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin-bottom: 20px;  
}
.cont-imagenes{
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cont-form-contacto-detalle{
    width: 28%;
    height: 700px;
    display: flex;
    align-items: stretch;
}
.info-textos{
    width: 35%;
    height: 550px;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
    margin-left: 20px;
    color: black;
    border-radius: 10px;
    background-color: #fff;
}
.info-textos span{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50px;
    border-radius: 10px 10px 0 0;
    color: var(--primary-color);
    background-color: rgba(63, 62, 62, 0.7); 
    font-family: Poppins;
    font-weight: 800;
    font-size: large;  
}
.info-textos .cont-datos {
    display: flex;

}
.info-textos p {
    font-family: Poppins;
    font-weight: 700;
    font-size: large;
    margin: 5px 0 5px 10px;
}
.info-textos b {
    font-weight: 800;
}
/* cont descr  */
.cont-titulo-descripcion-form{
    display: flex;
    justify-content: space-around;
    align-items: start;
    width: 95%;
}
/* cont descrip prop */
.cont-descrip{
    display: flex;
    min-height: 350px;
    height: auto;
    flex-direction: column;
    justify-content: start;
    align-items: center;
    width: 47.5%;
    border-radius: 20px;
    background-color: var(--color-negro);
}
.caracteristicas-prop{
    width: 47.5%;
    height: auto;
}
.titulo-descrip-prop {
    color: var(--color-blanco);
    font-size: 28px;
    font-weight: 200;
    margin: 0;
    padding: 15px;
    width: 90%;
    border-bottom: 1px solid var(--color-blanco);
}
.cont-detalle{
    width: 28%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: black;
}
.p-descrip-detalle,
.titulo-detalle-prop{
    font-size: 20px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif ;
    font-weight: 300;
    padding: 5px 20px;
    color: var(--color-blanco);
}
.titulo-detalle-prop{
    color: rgb(253, 252, 252);
}
.col-descrip-prop{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: center;
}
.col-descrip-prop-1 {
    width: 48%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 8px;
}
.cont-p-col-1 {
    width: 88%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: rgb(249, 248, 248);
    margin-top: 3px;
}
.ultimo{
    margin-bottom: 10px;
}
.p-col-1 {
    font-size: 20px;
    font-weight: 500;
    color: black;
    padding-left: 5px;
    padding-right: 5px;
}
/* cont descripción */
.cont-titulo-descripcion-form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
}
.cont-descrip{
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}
/* Lista */
.description-list{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin-top: 10px;
    margin-bottom: 10px;
}
.description-list .list-item {
    display: flex;
    margin-left: 30px;
    margin-bottom: 10px;
    line-height: 1.5;
    word-wrap: break-word;
    font-size: 20px;
    color: #fff;
}
/* Texto con margen alineado */
.description-list .list-item {
    text-indent: -1em;
    /* Retrocede la primera línea para compensar la viñeta */
    padding-left: 1.5em;
    /* Añade margen para el resto del texto */
    overflow-wrap: break-word;
    /* Divide palabras largas */
}
/* mapa */
.cont-map{
    width: 90%;
    height: 550px;
    margin-top: 20px;
}
.p-titulo-mapa{
    width: 250px;
    font-size: 23px;
    font-weight: 200;
    padding: 10px;
    color: var(--color-negro-logo);
    background-color: var(--color-gris);
    border-bottom: 1px solid black;
}
/* responsive */
@media screen and (max-width: 1300px){
    .info-1{
        width: 92%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .cont-titulo-detalle {
        width: 99%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .cont-btns-direccion{
        width: 95%;
        display: flex;
        justify-content: center;
        justify-content: space-between;
        align-items: center;
    }
    .detalle-titulo-prop {
        font-size: 25px;
    }
    .detalle-titulo-direccion {
        font-size: 18px;   
    }
    .cont-imgs-info{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
    }
    .cont-imagenes{
        width: 90%;
    }
    .info-imagenes{
        width: 70%;
    }
    .info-imagenes, .info-textos{
        width: 70%;
        margin-left: 0;
        margin-top: 15px;
    }
    /* cont detalle */
    .cont-detalle{
        width: 90%;
        height: auto;
        margin-top: 20px;
        margin-bottom: 20PX;
    }
    /* cont descripción */
    .cont-titulo-descripcion-form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 90%;
    }
    .cont-descrip{
        width: 100%;
        height: auto;
        margin-bottom: 20px;
    }
    .description-list .list-item {
        font-size: 18px;
    }
    /* mapa */
    .cont-map{
    width: 70%;
    height: 550px;
    margin-top: 20px;
    }
}
@media screen and (max-width: 960px){
    .info-1{
        width: 95%;
        display: flex;
        height: auto;
    }
    .cont-info-titulo{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .cont-info-precio{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .detalle-titulo-prop,
    .detalle-titulo-direccion {
        font-size: 18px;   
    }
    .detalle-precio {
        margin-right: 10px;
        font-size: 20px;
    }
    .cont-imgs-info{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 0;
    }
    .cont-imagenes{
        width: 90%;
    }
    .info-imagenes{
        width: 70%;
    }
    .info-imagenes, .info-textos{
        width: 70%;
        margin-left: 0;
        margin-top: 15px;
    }
    /* cont detalle */
    .cont-detalle{
        width: 90%;
        height: auto;
        margin-top: 20px;
        margin-bottom: 20PX;
    }
    /* cont descripción */
    .cont-titulo-descripcion-form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .cont-descrip{
        width: 90%;
        height: auto;
        margin-bottom: 20px;
    }
    /* mapa */
    .cont-map{
    width: 90%;
    height: 550px;
    margin-top: 20px;
    }
}
@media screen and (max-width: 550px){
    .info-1{
        width: 95%;
    }
    .cont-titulo-detalle {
        width: 99%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .cont-btns-direccion{
        width: 95%;
        display: flex;
        justify-content: center;
        justify-content: space-between;
        align-items: center;
    }
    .cont-detalle{
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        margin: 10px 0;
        background-color: black;
    }
    .cont-p-col-1 {
        width: 95%;
        display: flex;
        justify-content: space-around;
        background-color: #fff;
        margin-top: 3px;
    
    }
    .p-col-1 {
        font-size: 15px;
        font-weight: 500;
        color: black;
    }
    .detalle-titulo-prop{
        margin-left: 10px;
        font-size: 20px;
        font-weight: 500;
        color: var(--primary-color);
    }
    .detalle-titulo-direccion{
        margin: 3px;
        font-size: 17px;
        font-weight: 400;
        color: var(--primary-color);
    }
    .cont-imagenes{
        width: 100%;
    }
    /* cont form */
    .cont-form-contacto-detalle{
        width: 95%;
        height: auto;
        margin-top: 20px;
        margin-bottom: 20PX;
    }
    /* cont descripción */
    .cont-titulo-descripcion-form{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .cont-descrip{
        width: 100%;
        height: auto;
        margin-bottom: 20px;
    }
    .p-descrip-detalle{
        width: 90%;
        margin: 10px 10px;
        
    }
    /* mapa */
    .cont-map{
    width: 95%;
    height: 550px;
    margin-top: 0px;
    }
}