.cont-tabla {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.tabla-props-admin {
    width: 100%;
    max-width: 1200px;
    border-collapse: collapse;
    background-color: #f5f5f5;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    table-layout: fixed;
}

.tabla-props-admin th,
.tabla-props-admin td {
    padding: 10px;
    text-align: center;
}

.tabla-props-admin thead {
    background-color: #e0e0e0;
}

.tabla-props-admin thead th {
    font-weight: bold;
}

.tabla-props-admin tbody tr {
    background-color: #fff;
}

.tabla-props-admin tbody tr:nth-child(even) {
    background-color: #e7e6e6;
}

.tabla-props-admin tbody tr:hover {
    background-color: #f1f1f1;
}

.foto-tabla-prop {
    width: 50px;
    height: 50px;
    border-radius: 5px;
    object-fit: cover;
}

/* responsive */
@media screen and (max-width:680px) {
    .cont-tabla {
        width: 100%;
    }
    .tabla-props-admin thead th{
        font-size: 10px;
    }
    .tabla-props-admin tbody tr{
        font-size: 12px;
    }
}