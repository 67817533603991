.cont-landing-B {
    width: 100%;
    height: 707px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--color-negro);
}
.texto-somos {
    text-align: center;
    font-size: 30px;
    color: var(--color-blanco);
    margin: -30px 0 10px 0;
}
.logo-landing-B {
    height: 23rem;
    border-radius: 10px;
}
/* Botón contacto */
.btn-contactanos-home {
    margin-top: 40px;
    width: 200px;
    padding: 10px 8px;
    border: 1px solid var(--color-blanco);
    border-radius: 20px;
    font-size: 25px;
    font-weight: 100;
    color: var(--color-blanco);
    background-color: transparent;
    cursor: pointer;
    box-shadow: 0 0 0 0 rgba(202, 203, 204, 0.7);
    animation: pulse 2s infinite;
}
@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(241, 241, 242, 0.7);
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(52, 152, 219, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(52, 152, 219, 0);
    }
}
.texto-inf-landingB {
    text-align: center;
    font-size: 23px;
    color: var(--color-blanco);
}

/* responsive */
@media screen and (max-width: 700px){
    .logo-landing-B {
        height: 17rem;
        border-radius: 10px;
    }
    .texto-inf-landingB {
        font-size: 18px;
        padding: 5px 10px;
    }
}