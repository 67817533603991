.home {
    width: 100%;
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.cont-barraLateral-Y-listaProps {
    position: relative; /* para manejar el titulo */
    width: 100%;
    height: auto;
    display: flex;
    justify-content: start;
    align-items: start;
    background-color: rgb(222, 222, 222);
} 
.titulo-lista-props {
    position: absolute;
    top: -20px;
    left: 50%;
    transform: translate(-50%, -50%); /* Centra horizontalmente y ajusta verticalmente */
    width: fit-content; /* Ajusta el ancho al contenido */
    text-align: center;
    font-size: 28px;
    padding: 10px 25px;
    border-radius: 10px;
    color: var(--color-blanco);
    background-color: var(--color-negro);
}
.cont-barraLateral {
    position: sticky;
    top: 10%; /* La distancia desde la parte superior de la ventana */
    max-height: calc(100vh - 20px); /* Ajusta la altura máxima para evitar que salga del contenedor */
    overflow-y: auto; /* Permite el desplazamiento dentro de la barra lateral si el contenido es más largo */
    margin-top: 70px;
}

.cont-listaProps-Y-paginacion {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
}

/* responsive */

@media screen and (max-width: 900px){
    .cont-barraLateral-Y-listaProps {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .cont-barraLateral {
        position: relative;
        height: auto;
    }
    .cont-listaProps-Y-paginacion {
        width: 95%;
    }
    .titulo-lista-props {
        position: absolute;
        top: -10px;
        left: 50%;
        transform: translate(-50%, -50%); /* Centra horizontalmente y ajusta verticalmente */
        width: 300px; 
        text-align: center;
        font-size: 15px;
        padding: 10px 25px;
        border-radius: 10px;
        color: var(--color-blanco);
        background-color: var(--color-negro);
    }
}