.cont-loading{
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.logo-loading{
    width: 300px;
    height: 300px;
}