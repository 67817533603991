.price-range-filter {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    width: 100%;
}
.price-range-slider {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    margin: 20px 0;
}
.slider {
    width: 45%;
    height: 6px;
    background: #ddd;
    outline: none;
    opacity: 0.7;
    transition: opacity 0.2s;
    margin: 0 10px;
}
.slider:hover {
    opacity: 1;
}
.price-range-values {
    font-size: 18px;
    font-weight: bold;
    color: var(--color-negro);
    margin-bottom: 10px;
}
.btn-filtros{
    width: auto;
    font-size: medium;
    margin-bottom: 10px;
    padding: 8px;
    cursor: pointer;
    border-radius: 10px;
    color: var(--color-blanco);
    background-color: var(--color-negro);
}
