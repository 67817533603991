/* WhatsAppButton.css */
.whatsapp-button {
    position: fixed;
    width: 40px;
    height: 40px;
    bottom: 10px;
    right: 10px;    
    color: #fff;
    border-radius: 50%;
    text-align: center;
    z-index: 1000;
}

.whatsapp-button img {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 600px){
    .whatsapp-button {
        display: none;
    }
}