.cont-nosotros {
    width: 100%;
    min-height: 90vh;
    display: flex;
    justify-content: start;
    align-items: start;
    background-image: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0)), url('../../imagenes/img-2.jpg');
    background-size: cover; 
    background-position: center;
}
.cont-textos-nosotros {
    width: 60%;
    min-height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    margin: 80px 0 0 50px;
}
.p-texto-nosotros {
    font-size: 28px;
    font-weight: 500;
    padding: 10px;
    margin: 0;
    color: var(--color-blanco);
}

/* Responsive */
@media screen and (max-width: 600px) {
    .cont-textos-nosotros {
        width: 80%;
    }
    .p-texto-nosotros {
        font-size: 18px;
        font-weight: 500;
        padding: 10px;
        margin: 0;
        color: var(--color-blanco);
    }
}