.cont-filtros-listaProps-fav{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: start;
    margin-bottom: 10px;
}
.titulo-propsFav{
    text-align: center;
}
/* responsive */
@media screen and (max-width: 900px) {
    .cont-filtros-listaProps-fav{
        flex-direction: column;
        align-items: center;
    }
    .cont-barraL {
        position: relative;
        height: auto;
    }
}
