.cont-listaUsuarios th,
.cont-listaUsuarios td {
    padding: 12px 15px;
    text-align: left;
    border-bottom: 1px solid #ddd;
}

.cont-listaUsuarios thead {
    background-color: #151515e7;
    color: white;
}

.cont-listaUsuarios thead th {
    font-weight: bold;
    text-transform: uppercase;
}

.cont-listaUsuarios tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

.cont-listaUsuarios tbody tr td {
    font-size: 18px;
    font-weight: 600;
    color: black;
}

.cont-listaUsuarios tbody tr:hover {
    background-color: #e9e9e9;
}

.cont-listaUsuarios button {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s;
}

.cont-listaUsuarios button:hover {
    background-color: #0056b3;
    color: white;
}

.cont-listaUsuarios button:focus {
    outline: none;
}

.cont-listaUsuarios .btn-modificar {
    background-color: #28a745;
    color: white;
}

.cont-listaUsuarios .btn-eliminar {
    background-color: #dc3545;
    color: white;
}

/* responsive */
@media screen and (max-width: 600px) {
    .cont-listaUsuarios thead th {
        font-size: 15px;
    }
    .cont-listaUsuarios tbody tr td {
        font-size: 15px;
        font-weight: 600;
        color: black;
    }
}