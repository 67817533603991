.map-container {
    width: 100%;
    height: 400px; 
  }
  
  .map {
    width: 100%;
    height: 100%;
    border: none; /* Elimina el borde del iframe */
  }
  