.contGralLanding{ /* el padre de este cont es el de HOME */
    position: relative; /* relative PARA el zocalo */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 620px;
    background-image: url('../../imagenes/img-1.jpg');
    background-size: cover; 
    background-position: center;
}
/* estilos cont logo */
.cont-logo-landing{
    width: 100%;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sub-cont-logo-landing{
    width: 280px;
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--color-negro-logo);
}
.logo-landing{
    width: 280px;
    height: 280px;
    border-radius: 50px;
    animation: fadeIn 3s ease-in-out; /* desvanecimiento */ 
    /* animation: zoom 3s infinite; */ /* zoom */
}
/* Animación de desvanecimiento */
@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
/* para la animacion */
.sub-cont-sup, 
.sub-cont-infe {
    opacity: 0; /* Inicialmente, ocultamos los textos */
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
}  
.left-slide {
    animation-name: slideFromLeft;
}
.right-slide {
    animation-name: slideFromRight;
}
@keyframes slideFromLeft {
    from {
        opacity: 0;
        transform: translateX(-100%);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}
@keyframes slideFromRight {
    from {
        opacity: 0;
        transform: translateX(100%);
    }

    to {
        opacity: 1;
        transform: translateX(0);
    }
}
/* estilos cont y text inf */
.cont-inf {
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
}
.sub-cont-infe{
    width: 70%;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    /* background-color: var(--color-negro-logo); */
}
.btn-landing{
    width: 250px;
    font-size: 28px;
    font-weight: 500;
    padding: 10px;
    margin-left: 10px;
    color: var(--color-blanco);
    border: none;
    /* border: 1px solid var(--primary-color); */
    border-radius: 30px;
    background-color: var(--color-negro);
    cursor: pointer;
}

/* reponsive */
@media (max-width: 950px) {    
    .logo-landing{
        width: 150px;
        height: 150px;
    }
    .btn-landing{
        width: auto;
        font-size: 20px;
        font-weight: 200;
        padding: 12px 10px;
        margin-left: 10px;
    }
}
@media screen and (max-width: 660px){
    .sub-cont-logo-landing{
        width: 230px;
        height: 230px;
    }
    .sub-cont-inf{
        width: 95%;
    }
    .btn-landing{
        width: auto;
        font-size: 20px;
        font-weight: 200;
        padding: 12px 10px;
        margin-left: 10px;
    }
}